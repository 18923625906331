import React, { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import emailjs from 'emailjs-com'
import { MdDone } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { AiFillMail, AiFillPhone, AiFillPushpin } from 'react-icons/ai'

import Mapbox from '../components/Mapbox'

const ReachUs = () => {
  const { t } = useTranslation()

  const [isFormSent, setIsFormSent] = useState(false)
  const form = useRef()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_USER_ID,
      )

      .then(
        (result) => console.log(result.text),
        (error) => console.log(error.text),
      )
    setIsFormSent(true)
    e.target.reset()
  }

  const [isExpanded, setIsExpanded] = useState({
    lisboa: true,
    porto: false,
    faro: false,
  })

  const handleToggle = (section) => {
    setIsExpanded((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }))
  }

  return (
    <div className="pt-24 bg-awhite md:px-44 px-12 pb-44">
      <div className="">
        <p className="text-4xl flex justify-center text-center font-title text-agreen">
          {t('contact.title')}
        </p>
        <p className="flex justify-center md:text-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 md:mx-10">
          {t('contact.subtitle')}
        </p>
      </div>

      <div className="md:flex justify-center lg:justify-around mt-24">
        <div className="lg:flex justify-between md:mx-10">
          <form
            ref={form}
            onSubmit={sendEmail}
            className="border-2 border-agreen shadow-lg rounded-lg p-10"
          >
            <div className="flex justify-between">
              <div className=" mr-5 z-10">
                <input
                  type="text"
                  placeholder={t('contact.name')}
                  name="user_name"
                  className=" rounded w-[100%] p-4 bg-awhite"
                  required
                />
                <hr className="text-black bg-black z-1" />
              </div>
              <div className="z-10">
                <input
                  type="text"
                  placeholder={t('contact.email')}
                  name="user_email"
                  className=" rounded w-[100%] p-4 bg-awhite"
                  required
                />
                <hr className="text-black bg-black z-1" />
              </div>
            </div>
            <div className="z-10">
              <textarea
                type="text"
                placeholder={t('contact.message')}
                name="message"
                className=" rounded w-[100%] mt-10 p-4 bg-awhite "
                required
              />
            </div>
            {isFormSent ? (
              <div className="flex items-center justify-center">
                <MdDone className="text-agreen mr-2" />
                <span className="text-agreen font-bold">
                  {t('contact.sent')}
                </span>
              </div>
            ) : (
              <button
                type="submit"
                className="bg-ayellow hover:text-awhite font-title font-bold py-1.5 mt-3 w-full px-4 rounded-full hover:bg-agreen duration-500"
              >
                {t('contact.send')}
              </button>
            )}
          </form>
          <div className=" mt-10 lg:mt-0 lg:ml-10 shadow-lg py-10 lg:mx-5 bg-agreen ml-auto mr-auto ">
            <div className="">
              <div className="font-text flex px-3 lg:mt-5 md:mt-3 text-white">
                <AiFillPhone className="mt-1 mr-2" />
                <p>(+351) 961 444 039</p>
                <p className='pl-2'>({t('footer.chamada')})</p>
              </div>
              <div className="font-text flex px-3 lg:mt-5 md:mt-3 text-white">
                <AiFillMail className="mt-1 mr-2" />
                info@quintadosribeiros.com
              </div>
              <div className="font-text flex px-3 lg:mt-5 md:mt-3 text-white">
                <AiFillPushpin className="mt-1 mr-2" />
                Estr. Nacional 245 Km 8, 6050-054 Alpalhão
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-4xl flex justify-center text-center font-title text-agreen pt-20">
        {t('contact.find')}
      </div>
      <p className="flex justify-center md:text-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 pb-20 md:mx-10">
        {t('contact.find-sub')}
      </p>
      <div className="flex  lg:flex-row flex-col">
        <Mapbox />
        <div className="mt-10 lg:mt-0 lg:ml-24 ">
          <h5 className="font-title font-bold text-agreen">
            <span
              className="cursor-pointer"
              onClick={() => handleToggle('lisboa')}
            >
              {isExpanded.lisboa ? '▲ ' : '▼ '}
              {t('contact.lisboa')}
            </span>
          </h5>
          {isExpanded.lisboa && (
            <p className="text-sm text-justify">{t('contact.lisboa-desc')}</p>
          )}
          <h5 className="font-title font-bold text-agreen">
            <span
              className="cursor-pointer"
              onClick={() => handleToggle('porto')}
            >
              {isExpanded.porto ? '▲ ' : '▼ '}
              {t('contact.porto')}
            </span>
          </h5>
          {isExpanded.porto && (
            <p className="text-sm text-justify">{t('contact.porto-desc')}</p>
          )}

          <h5 className="font-title font-bold text-agreen">
            <span
              className="cursor-pointer"
              onClick={() => handleToggle('faro')}
            >
              {isExpanded.faro ? '▲ ' : '▼ '}
              {t('contact.faro')}
            </span>
          </h5>
          {isExpanded.faro && (
            <p className="text-sm text-justify">{t('contact.faro-desc')}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReachUs
