import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import "./cards.css";
import { useTranslation } from "react-i18next";

const Region = () => {
  const { t } = useTranslation();

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const [isHoveredalpa, setIsHoveredalpa] = useState(false);

  const [isHoveredmarv, setIsHoveredmarv] = useState(false);

  const [isHoverednisa, setIsHoverednisa] = useState(false);

  const [isHoveredporta, setIsHoveredporta] = useState(false);

  const [isHoveredavora, setIsHoveredavora] = useState(false);

  const [isHoveredalter, setIsHoveredalter] = useState(false);

  const [isHoveredcastelo, setIsHoveredcastelo] = useState(false);

  const [isHoveredcrato, setIsHoveredcrato] = useState(false);

  const [isHoveredrando, setIsHoveredrando] = useState(false);

  const [isHoveredpadel, setIsHoveredpadel] = useState(false);

  const [isHoveredpovoa, setIsHoveredpovoa] = useState(false);

  const [isHoveredwake, setIsHoveredwake] = useState(false);

  const [isHoveredfete, setIsHoveredfete] = useState(false);

  const [isHoveredkarting, setIsHoveredkarting] = useState(false);

  const [isHoveredanimals, setIsHoveredanimals] = useState(false);

  const [isHovereddolmen, setIsHovereddolmen] = useState(false);
  return (
    <div className="px-10">
      <p className="text-4xl flex justify-center text-center font-title text-agreen pt-20">
        {t("region.title")}
      </p>

      <p className="flex justify-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 md:mx-24">
        {t("region.subtitle")}
      </p>
      <p className="flex justify-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 md:mx-24">
        {t("region.subtitle-2")}
      </p>
      <p className="flex justify-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 md:mx-24">
        {t("region.subtitle-3")}
      </p>

      <p className="text-4xl flex justify-center text-center font-title text-agreen py-20">
        {t("region.villages")}
      </p>

      <motion.div
        className="md:grid space-y-5 md:space-y-0 grid-cols-2 lg:grid-cols-4 grid-rows-4 lg:grid-rows-2 gap-5 pt-5 pb-20"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className={`col-start-1 row-start-1 px-5 ${
            isHoveredalpa ? "hover" : ""
          }`}
          variants={item}
          onHoverStart={() => setIsHoveredalpa(true)}
          onHoverEnd={() => setIsHoveredalpa(false)}
        >
          <div
            className={`${isHoveredalpa ? "card flipped" : "card"}`}
            style={{
              borderTopColor: "#F8B533",
              borderTopWidth: "15px",
              borderTopStyle: "solid",
            }}
          >
            <div className="front alpa">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  Alpalhão
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                <p>{t("region.desc-alpa")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className={`col-start-2 row-start-1 px-5 ${
            isHoveredmarv ? "hover" : ""
          }`}
          variants={item}
          onHoverStart={() => setIsHoveredmarv(true)}
          onHoverEnd={() => setIsHoveredmarv(false)}
        >
          <div
            className={`${isHoveredmarv ? "card flipped" : "card"}`}
            style={{
              borderLeftColor: "#F8B533",
              borderLeftWidth: "15px",
              borderLeftStyle: "solid",
            }}
          >
            <div className="front marv">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  Marvão
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.desc-marvao")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className={`col-start-1 lg:col-start-3 row-start-2 lg:row-start-1 px-5 ${
            isHoverednisa ? "hover" : ""
          }`}
          // className="col-start-1 lg:col-start-3 row-start-2 lg:row-start-1 px-5"
          variants={item}
          onHoverStart={() => setIsHoverednisa(true)}
          onHoverEnd={() => setIsHoverednisa(false)}
        >
          <div
            className={`${isHoverednisa ? "card flipped" : "card"}`}
            style={{
              borderBottomColor: "#F8B533",
              borderBottomWidth: "15px",
              borderBottomStyle: "solid",
            }}
          >
            <div className="front nisa">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  Nisa
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.desc-nisa")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          // className="col-start-2 lg:col-start-4 row-start-2 lg:row-start-1 px-5"
          className={`col-start-2 lg:col-start-4 row-start-2 lg:row-start-1 px-5 ${
            isHoveredcrato ? "hover" : ""
          }`}
          variants={item}
          onHoverStart={() => setIsHoveredcrato(true)}
          onHoverEnd={() => setIsHoveredcrato(false)}
        >
          <div
            className={`${isHoveredcrato ? "card flipped" : "card"}`}
            style={{
              borderRightColor: "#F8B533",
              borderRightWidth: "15px",
              borderRightStyle: "solid",
            }}
          >
            <div className="front crato">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  Crato
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.desc-crato")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className={`col-start-1  row-start-3 lg:row-start-2 px-5 ${
            isHoveredcastelo ? "hover" : ""
          }`}
          // className="col-start-1 row-start-3 lg:row-start-2 px-5"
          variants={item}
          onHoverStart={() => setIsHoveredcastelo(true)}
          onHoverEnd={() => setIsHoveredcastelo(false)}
        >
          <div
            className={`${isHoveredcastelo ? "card flipped" : "card"}`}
            style={{
              borderRightColor: "#F8B533",
              borderRightWidth: "15px",
              borderRightStyle: "solid",
            }}
          >
            <div className="front castelo">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  Castelo de Vide
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.desc-castelo")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className={`col-start-2 row-start-3 lg:row-start-2 px-5 ${
            isHoveredporta ? "hover" : ""
          }`}
          //className="col-start-2 row-start-3 lg:row-start-2 px-5"
          variants={item}
          onHoverStart={() => setIsHoveredporta(true)}
          onHoverEnd={() => setIsHoveredporta(false)}
        >
          <div
            className={`${isHoveredporta ? "card flipped" : "card"}`}
            style={{
              borderBottomColor: "#F8B533",
              borderBottomWidth: "15px",
              borderBottomStyle: "solid",
            }}
          >
            <div className="front porta">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  Portalegre
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.desc-portalegre")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className={`col-start-1 lg:col-start-3 row-start-4 lg:row-start-2 px-5 ${
            isHoveredalter ? "hover" : ""
          }`}
          // className="col-start-1 lg:col-start-3 row-start-4 lg:row-start-2 px-5"
          variants={item}
          onHoverStart={() => setIsHoveredalter(true)}
          onHoverEnd={() => setIsHoveredalter(false)}
        >
          <div
            className={`${isHoveredalter ? "card flipped" : "card"}`}
            style={{
              borderLeftColor: "#F8B533",
              borderLeftWidth: "15px",
              borderLeftStyle: "solid",
            }}
          >
            <div className="front alter">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  Tomar
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.desc-tomar")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className={`col-start-2 lg:col-start-4 row-start-4 lg:row-start-2 px-5 ${
            isHoveredavora ? "hover" : ""
          }`}
          // className="col-start-2 lg:col-start-4 lg:row-start-2 row-start-4 px-5"
          variants={item}
          onHoverStart={() => setIsHoveredavora(true)}
          onHoverEnd={() => setIsHoveredavora(false)}
        >
          <div
            className={`${isHoveredavora ? "card flipped" : "card"}`}
            style={{
              borderTopColor: "#F8B533",
              borderTopWidth: "15px",
              borderTopStyle: "solid",
            }}
          >
            <div className="front evora">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  Evora
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.desc-evora")}</p>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>

      <hr className="border-2 border-agreen" />

      <p className="text-4xl flex justify-center text-center font-title text-agreen py-20">
        {t("region.activities")}
      </p>

      <motion.div
        className="md:grid space-y-5 md:space-y-0 grid-cols-2 lg:grid-cols-4 grid-rows-4 lg:grid-rows-2 gap-5 pt-5 pb-20"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className={`col-start-1 row-start-1 px-5 ${
            isHoveredrando ? "hover" : ""
          }`}
          variants={item}
          onHoverStart={() => setIsHoveredrando(true)}
          onHoverEnd={() => setIsHoveredrando(false)}
        >
          <div
            className={`${isHoveredrando ? "card flipped" : "card"}`}
            style={{
              borderTopColor: "#AACB3D",
              borderTopWidth: "15px",
              borderTopStyle: "solid",
            }}
          >
            <div className="front rando">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  {t("region.rando-title")}
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.randos")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className={`col-start-2 row-start-1 px-5 ${
            isHoveredpadel ? "hover" : ""
          }`}
          variants={item}
          onHoverStart={() => setIsHoveredpadel(true)}
          onHoverEnd={() => setIsHoveredpadel(false)}
        >
          <div
            className={`${isHoveredpadel ? "card flipped" : "card"}`}
            style={{
              borderLeftColor: "#AACB3D",
              borderLeftWidth: "15px",
              borderLeftStyle: "solid",
            }}
          >
            <div className="front padel">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  Padel
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.padel")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className={`col-start-1 lg:col-start-3 row-start-2 lg:row-start-1 px-5 ${
            isHoveredpovoa ? "hover" : ""
          }`}
          // className="col-start-1 lg:col-start-3 row-start-2 lg:row-start-1 px-5"
          variants={item}
          onHoverStart={() => setIsHoveredpovoa(true)}
          onHoverEnd={() => setIsHoveredpovoa(false)}
        >
          <div
            className={`${isHoveredpovoa ? "card flipped" : "card"}`}
            style={{
              borderBottomColor: "#AACB3D",
              borderBottomWidth: "15px",
              borderBottomStyle: "solid",
            }}
          >
            <div className="front povoa">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  Barragem da Povoa e Meadas
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.baragem")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          // className="col-start-2 lg:col-start-4 row-start-2 lg:row-start-1 px-5"
          className={`col-start-2 lg:col-start-4 row-start-2 lg:row-start-1 px-5 ${
            isHoveredwake ? "hover" : ""
          }`}
          variants={item}
          onHoverStart={() => setIsHoveredwake(true)}
          onHoverEnd={() => setIsHoveredwake(false)}
        >
          <div
            className={`${isHoveredwake ? "card flipped" : "card"}`}
            style={{
              borderRightColor: "#AACB3D",
              borderRightWidth: "15px",
              borderRightStyle: "solid",
            }}
          >
            <div className="front wake">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  Wakeboard
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.wake")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className={`col-start-1  row-start-3 lg:row-start-2 px-5 ${
            isHoveredfete ? "hover" : ""
          }`}
          // className="col-start-1 row-start-3 lg:row-start-2 px-5"
          variants={item}
          onHoverStart={() => setIsHoveredfete(true)}
          onHoverEnd={() => setIsHoveredfete(false)}
        >
          <div
            className={`${isHoveredfete ? "card flipped" : "card"}`}
            style={{
              borderRightColor: "#AACB3D",
              borderRightWidth: "15px",
              borderRightStyle: "solid",
            }}
          >
            <div className="front fete">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  {t("region.parties-title")}
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.parties")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className={`col-start-2 row-start-3 lg:row-start-2 px-5 ${
            isHoveredkarting ? "hover" : ""
          }`}
          //className="col-start-2 row-start-3 lg:row-start-2 px-5"
          variants={item}
          onHoverStart={() => setIsHoveredkarting(true)}
          onHoverEnd={() => setIsHoveredkarting(false)}
        >
          <div
            className={`${isHoveredkarting ? "card flipped" : "card"}`}
            style={{
              borderBottomColor: "#AACB3D",
              borderBottomWidth: "15px",
              borderBottomStyle: "solid",
            }}
          >
            <div className="front karting">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  Karting
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.karting")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className={`col-start-1 lg:col-start-3 row-start-4 lg:row-start-2 px-5 ${
            isHoveredanimals ? "hover" : ""
          }`}
          // className="col-start-1 lg:col-start-3 row-start-4 lg:row-start-2 px-5"
          variants={item}
          onHoverStart={() => setIsHoveredanimals(true)}
          onHoverEnd={() => setIsHoveredanimals(false)}
        >
          <div
            className={`${isHoveredanimals ? "card flipped" : "card"}`}
            style={{
              borderLeftColor: "#AACB3D",
              borderLeftWidth: "15px",
              borderLeftStyle: "solid",
            }}
          >
            <div className="front animals">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  {t("region.animals-title")}
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.animals")}</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className={`col-start-2 lg:col-start-4 row-start-4 lg:row-start-2 px-5 ${
            isHovereddolmen ? "hover" : ""
          }`}
          // className="col-start-2 lg:col-start-4 lg:row-start-2 row-start-4 px-5"
          variants={item}
          onHoverStart={() => setIsHovereddolmen(true)}
          onHoverEnd={() => setIsHovereddolmen(false)}
        >
          <div
            className={`${isHovereddolmen ? "card flipped" : "card"}`}
            style={{
              borderTopColor: "#AACB3D",
              borderTopWidth: "15px",
              borderTopStyle: "solid",
            }}
          >
            <div className="front dolmen">
              <div />
              <div className="content">
                <h2 className="text-white text-4xl font-title font-bold">
                  {t("region.dolmen-title")}
                </h2>
              </div>
            </div>
            <div className="back">
              <div className="description">
                {/* Add your description here */}
                <p>{t("region.dolmen")}</p>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Region;
