import React from 'react'
import { motion } from 'framer-motion'
import Sun from '../images/sun.png'
import Papillon from '../images/papillon.png'
import Sansdessin from '../images/sansdessin.png'
import Feuille from '../images/Feuille.png'
import Aquarelle4 from '../images/aquarelle4.png'
import Logo from '../images/logo.png'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

const Farm = () => {
  const isMediumOrMore = useMediaQuery({ minWidth: 768 })

  const { t } = useTranslation()

  return (
    <>
      <div className="bg-awhite pt-20">
        <div className="ml-44">
          <div className="flex flex-row justify-center">
            <motion.div
              initial={{ opacity: 0, x: '-100%', y: '-75%' }}
              animate={{ opacity: 1, x: '130%', y: '10%' }}
              transition={{
                duration: 8,
                delay: 1,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              className="invisible md:visible"
            >
              <img src={Papillon} alt="papillon" width={100} height={100} />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: '-100%', y: '40%' }}
              animate={{ opacity: 1, x: '30%', y: '40%' }}
              transition={{
                duration: 8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              className="invisible md:visible"
            >
              <img
                src={Feuille}
                alt="petite feuille"
                width={100}
                height={100}
              />
            </motion.div>
            {isMediumOrMore ? (
              <img
                src={Sansdessin}
                alt="logo sans petits dessins"
                width={300}
                height={300}
              />
            ) : (
              <img
                src={Logo}
                alt="logo avec petits dessins"
                width={300}
                height={300}
                className="mr-32"
              />
            )}
            <motion.div
              initial={{ opacity: 0, x: '100%', y: '-100%' }}
              animate={{ opacity: 1, x: '-40%', y: '30%' }}
              transition={{
                duration: 8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              className="invisible md:visible"
            >
              <img src={Sun} alt="sun" width={100} height={100} />
            </motion.div>
          </div>
        </div>
        <div>
          <h1 className="text-4xl text-agreen flex justify-center pt-8 mx-12 text-center">
            {t('farm.title')}
          </h1>
          <h3 className="text-2xl flex justify-center text-start pt-8 mx-8 md:mx-32">
            {t('farm.subtitle')}
          </h3>
        </div>
        <div className="lg:flex ">
          <div className="flex justify-center px-10 md:px-20 lg:px-10 mt-20   lg:ml-44 font-text  lg:w-1/4 text-justify pb-10  lg:pb-0 lg:min-h-[500px] md:mx-40 lg:mx-12 ">
            <div className="">
              <p>{t('farm.desc-1')}</p>
              <p>{t('farm.desc-2')}</p>
            </div>
          </div>

          <div>
            <div className="flex justify-center lg:flex-none lg:pl-20 lg:mt-20 px-10">
              <img
                src={Aquarelle4}
                alt="backhouse"
                width={500}
                height={500}
                className="min-h-[280px] object-cover"
              />
            </div>
          </div>
        </div>

        <div className="md:mx-44 mx-12">
          <p className="text-justify font-text py-5">
          <p>{t('farm.desc-3')}</p>
          <p>{t('farm.desc-4')}</p>
          <p>{t('farm.desc-5')}</p>
          <p>{t('farm.desc-6')}</p>
          <p>{t('farm.desc-7')}</p>
          <p>{t('farm.desc-8')}</p>
          <p>{t('farm.desc-9')}</p>
          <p>{t('farm.desc-10')}</p>
          <p>{t('farm.desc-11')}</p>
          <p>{t('farm.desc-12')}</p>
          </p>
        </div>
      </div>
    </>
  )
}

export default Farm
