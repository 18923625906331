import React, { useEffect } from 'react'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faArrowRight,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const Photos = () => {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [slideNumber, setSlideNumber] = useState(0)

  const [openCasa, setOpenCasa] = useState(false)

  const [openMain, setOpenMain] = useState(false)

  const [openCeleiro, setOpenCeleiro] = useState(false)

  const handleOpenCasa = (index) => {
    setSlideNumber(index)
    setOpenCasa(true)
  }

  const handleOpenMain = (index) => {
    setSlideNumber(index)
    setOpenMain(true)
  }

  const handleOpenCeleiro = (index) => {
    setSlideNumber(index)
    setOpenCeleiro(true)
  }

  const handleMoveCasa = (direction) => {
    let newSlideNumber
    if (direction === 'l') {
      newSlideNumber = slideNumber === 0 ? 2 : slideNumber - 1
    } else {
      newSlideNumber = slideNumber === 2 ? 0 : slideNumber + 1
    }

    setSlideNumber(newSlideNumber)
  }

  const handleMoveMain = (direction) => {
    let newSlideNumber
    if (direction === 'l') {
      newSlideNumber = slideNumber === 0 ? 11 : slideNumber - 1
    } else {
      newSlideNumber = slideNumber === 11 ? 0 : slideNumber + 1
    }

    setSlideNumber(newSlideNumber)
  }

  const handleMoveCeleiro = (direction) => {
    let newSlideNumber
    if (direction === 'l') {
      newSlideNumber = slideNumber === 0 ? 7 : slideNumber - 1
    } else {
      newSlideNumber = slideNumber === 7 ? 0 : slideNumber + 1
    }

    setSlideNumber(newSlideNumber)
  }

  const imagescasa = [
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684837856/Alpalhao/casa%20mae/IMG_6697_qs7fn7.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684837825/Alpalhao/casa%20mae/IMG_6695_kemvr8.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684837729/Alpalhao/casa%20mae/IMG_6685_djmj8j.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1687344803/casaMae/IMG_6797_jybnwn.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1687344713/casaMae/IMG_6770_sluzcs.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1687344720/casaMae/IMG_6776_q2v514.jpg',
    },

    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1687344691/casaMae/IMG_6753_vglonp.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1687344701/casaMae/IMG_6762_lopfvj.jpg',
    },
    {
      src:
        ' https://res.cloudinary.com/dowjj9cdi/image/upload/v1687344681/casaMae/IMG_6747_d5ztik.jpg',
    },
  ]

  const imagesmain = [
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684838407/Alpalhao/main/IMG_6726_rehkrv.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684838315/Alpalhao/main/IMG_6725_yz1ons.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684838270/Alpalhao/main/IMG_6710_cgm4jx.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684838202/Alpalhao/main/IMG_6707_qmsyyw.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684835304/Alpalhao/main/IMG_6679_esduqw.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684835251/Alpalhao/main/IMG_6672_adrmqf.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684835250/Alpalhao/main/IMG_6673_dlmd7m.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684835248/Alpalhao/main/IMG_6641_aboomu.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684835247/Alpalhao/main/IMG_6663_v30lov.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684834862/Alpalhao/main/IMG_6634_x3dowa.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684834850/Alpalhao/main/IMG_6633_knuw30.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684834784/Alpalhao/main/IMG_6622_wmulef.jpg',
    },
  ]

  const imagesceleiro = [
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684834685/Alpalhao/celeiro/IMG_6573_ffjwi0.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684834266/Alpalhao/celeiro/IMG_6596_hhoiwq.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684834248/Alpalhao/celeiro/IMG_6604_uudlpa.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684834193/Alpalhao/celeiro/IMG_6578_r6punu.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684834156/Alpalhao/celeiro/IMG_6580_gktx13.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684834100/Alpalhao/celeiro/IMG_6587_c4jjvo.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684833925/Alpalhao/celeiro/IMG_6568_taqdey.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1684833643/Alpalhao/celeiro/IMG_6618_zsdkag.jpg',
    },
  ]

  return (
    <>
      {openCasa && (
        <div className="sticky inset-0 w-screen h-screen bg-black bg-opacity-60 flex items-center z-30">
          <FontAwesomeIcon
            icon={faXmark}
            className="absolute top-[20px] right-[20px] text-[30px] text-agreen cursor-pointer"
            onClick={() => setOpenCasa(false)}
          />

          <FontAwesomeIcon
            icon={faArrowLeft}
            className="m-[20px] text-[50px] text-agreen cursor-pointer"
            onClick={() => handleMoveCasa('l')}
          />

          <div className="h-full w-full flex justify-center items-center">
            <img
              src={imagescasa[slideNumber].src}
              alt=":)"
              className="lg:w-4/5 w-full h-4/5 object-cover"
            />
          </div>

          <FontAwesomeIcon
            icon={faArrowRight}
            className="m-[20px] text-[50px] text-agreen cursor-pointer"
            onClick={() => handleMoveCasa('r')}
          />
        </div>
      )}

      <div>
        <p className="text-4xl flex justify-center text-center font-title text-agreen py-20">
          {t('photos.title')}
        </p>
      </div>

      <div className="md:flex-row md:flex md:flex-wrap mx-8">
        {imagescasa.map((image, index) => (
          <img
            src={image.src}
            alt="gallery"
            className="md:w-1/3 h-[300px] object-cover cursor-pointer p-3"
            onClick={() => handleOpenCasa(index)}
          />
        ))}
      </div>

      {openMain && (
        <div className="sticky inset-0 w-screen h-screen bg-black bg-opacity-60 flex items-center z-30">
          <FontAwesomeIcon
            icon={faXmark}
            className="absolute top-[20px] right-[20px] text-[30px] text-agreen cursor-pointer"
            onClick={() => setOpenMain(false)}
          />

          <FontAwesomeIcon
            icon={faArrowLeft}
            className="m-[20px] text-[50px] text-agreen cursor-pointer"
            onClick={() => handleMoveMain('l')}
          />

          <div className="h-full w-full flex justify-center items-center">
            <img
              src={imagesmain[slideNumber].src}
              alt=":)"
              className="lg:w-4/5 w-full h-4/5 object-cover"
            />
          </div>

          <FontAwesomeIcon
            icon={faArrowRight}
            className="m-[20px] text-[50px] text-agreen cursor-pointer"
            onClick={() => handleMoveMain('r')}
          />
        </div>
      )}

      <div>
        <p className="text-4xl flex justify-center text-center font-title text-agreen py-20">
          {t('photos.title-2')}
        </p>
      </div>

      <div className="md:flex-row md:flex md:flex-wrap mx-8">
        {imagesmain.map((image, index) => (
          <img
            src={image.src}
            alt="gallery"
            className="md:w-1/3 h-[300px] object-cover cursor-pointer p-3"
            onClick={() => handleOpenMain(index)}
          />
        ))}
      </div>

      {openCeleiro && (
        <div className="sticky inset-0 w-screen h-screen bg-black bg-opacity-60 flex items-center z-30">
          <FontAwesomeIcon
            icon={faXmark}
            className="absolute top-[20px] right-[20px] text-[30px] text-agreen cursor-pointer"
            onClick={() => setOpenCeleiro(false)}
          />

          <FontAwesomeIcon
            icon={faArrowLeft}
            className="m-[20px] text-[50px] text-agreen cursor-pointer"
            onClick={() => handleMoveCeleiro('l')}
          />

          <div className="h-full w-full flex justify-center items-center">
            <img
              src={imagesceleiro[slideNumber].src}
              alt=":)"
              className="lg:w-4/5 w-full h-4/5 object-cover"
            />
          </div>

          <FontAwesomeIcon
            icon={faArrowRight}
            className="m-[20px] text-[50px] text-agreen cursor-pointer"
            onClick={() => handleMoveCeleiro('r')}
          />
        </div>
      )}

      <div>
        <p className="text-4xl flex justify-center text-center font-title text-agreen py-20">
          {t('photos.title-3')}
        </p>
      </div>

      <div className="md:flex-row md:flex md:flex-wrap mx-8">
        {imagesceleiro.map((image, index) => (
          <img
            src={image.src}
            alt="gallery"
            className="md:w-1/3 h-[300px] object-cover cursor-pointer p-3"
            onClick={() => handleOpenCeleiro(index)}
          />
        ))}
      </div>
    </>
  )
}

export default Photos
