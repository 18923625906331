import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Farm from './pages/Farm';
import Home from './pages/Home';
import Contact from './pages/Contact'
import Region from './pages/Region';
import Photos from './pages/Photos'


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/farm' element={<Farm/>}/>
        <Route path='/region' element={<Region/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/photos' element={<Photos/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
