import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWaterLadder,
  faSquareParking,
  faMugSaucer,
  faPaw,
  faCakeCandles,
  faHouse,
  faBed,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Aquarelle1 from "../images/aquarelle1.png";
import Aquarelle2 from "../images/aquarelle2.png";
import Aquarelle3 from "../images/aquarelle3.png";
import Aquarelle0 from "../images/aquarelle0.png";
import Aquarelle5 from '../images/aquarelle5.png'
import casaMae from "../images/casamae.JPG";
import casaMain from "../images/main.JPG";
import celeiro from "../images/celeiro.JPG";
import casanova from "../images/casanova.jpg";

const Home = () => {
  const { t } = useTranslation();

  const [showWaterLadderCard, setShowWaterLadderCard] = useState(false);

  const handleWaterLadderIconHover = () => {
    setShowWaterLadderCard(true);
  };

  const handleWaterLadderIconLeave = () => {
    setShowWaterLadderCard(false);
  };

  const [showParkingCard, setShowParkingCard] = useState(false);

  const handleParkingIconHover = () => {
    setShowParkingCard(true);
  };

  const handleParkingIconLeave = () => {
    setShowParkingCard(false);
  };

  const [showMugCard, setShowMugCard] = useState(false);

  const handleMugIconHover = () => {
    setShowMugCard(true);
  };

  const handleMugIconLeave = () => {
    setShowMugCard(false);
  };

  const [showHouseCard, setShowHouseCard] = useState(false);

  const handleHouseIconHover = () => {
    setShowHouseCard(true);
  };

  const handleHouseIconLeave = () => {
    setShowHouseCard(false);
  };

  const [showBedCard, setShowBedCard] = useState(false);

  const handleBedIconHover = () => {
    setShowBedCard(true);
  };

  const handleBedIconLeave = () => {
    setShowBedCard(false);
  };

  const [showCakeCard, setShowCakeCard] = useState(false);

  const handleCakeIconHover = () => {
    setShowCakeCard(true);
  };

  const handleCakeIconLeave = () => {
    setShowCakeCard(false);
  };

  const [showPawCard, setShowPawCard] = useState(false);

  const handlePawIconHover = () => {
    setShowPawCard(true);
  };

  const handlePawIconLeave = () => {
    setShowPawCard(false);
  };

  return (
    <main className="text-black">
      <section>
        <div className="w-full h-screen z-[-1]">
          <img
            src={Aquarelle0}
            alt=""
            className="w-screen h-screen object-cover relative"
          />

          <div className="h-1/4 absolute top-52 right-0">
            <div className="text-3xl md:text-7xl p-4 flex mr-5 md:mr-8 ml-[50%] justify-end text-end">
              <p>{t("home.title")}</p>
            </div>
            <div className="flex mr-5 md:mr-8 ml-[50%] justify-end text-end">
              <Link to="/contact">
                <button className="bg-ayellow hover:text-awhite font-title font-bold py-2 px-4 mt-8 mr-5 hover:bg-agreen rounded-full duration-500">
                  {t("home.book")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className='md:mx-[10rem]'>
      <div className="md:px-80 px-12 pt-32 bg-awhite pb-16">
        <div className="flex flex-row justify-center">
          <div
            className="relative"
            onMouseEnter={handleWaterLadderIconHover}
            onMouseLeave={handleWaterLadderIconLeave}
          >
            <FontAwesomeIcon
              icon={faWaterLadder}
              className="md:mx-12 mx-5 text-xl md:text-5xl md:w-16 z-1 hover:text-agreen cursor-pointer"
            />
            {showWaterLadderCard && (
              <div className="md:left-[-50%] left-[0%] absolute md:w-[300px] md:h-[280px] w-[400%] md:top-[-500%] top-[-300%] p-7 bg-awhite border-2 border-agreen shadow-lg rounded-lg z-10 cursor-pointer">
                <p className="font-title text-sm md:text-base text-black">
                  {t("home.cards.swim")}
                </p>
              </div>
            )}
          </div>
          <div
            className="relative"
            onMouseEnter={handleMugIconHover}
            onMouseLeave={handleMugIconLeave}
          >
            <FontAwesomeIcon
              icon={faMugSaucer}
              className="md:mx-12 mx-5 text-xl md:text-5xl md:w-16 z-1 hover:text-agreen cursor-pointer "
            />
            {showMugCard && (
              <div className="md:left-[-50%] left-[-100%] absolute cursor-pointer md:w-[300px] md:h-[280px] w-[400%] md:top-[-500%] top-[-300%] p-7 bg-awhite border-2 border-agreen shadow-lg rounded-lg z-10">
                <p className="font-title text-black text-sm md:text-base">
                  {t("home.cards.breakfast")}
                </p>
              </div>
            )}
          </div>
          <div
            className="relative"
            onMouseEnter={handleHouseIconHover}
            onMouseLeave={handleHouseIconLeave}
          >
            <FontAwesomeIcon
              icon={faHouse}
              className="md:mx-12 mx-5 text-xl md:text-5xl md:w-16 z-1 hover:text-agreen cursor-pointer"
            />
            {showHouseCard && (
              <div className="md:left-[-50%] left-[-200%] cursor-pointer absolute md:w-[300px] md:h-[280px] w-[400%] md:top-[-500%] top-[-300%] p-7 bg-awhite border-2 border-agreen shadow-lg rounded-lg z-10">
                <p className="font-title text-black text-sm md:text-base">
                  {t("home.cards.house")}
                </p>
              </div>
            )}
          </div>
          <div
            className="relative"
            onMouseEnter={handleBedIconHover}
            onMouseLeave={handleBedIconLeave}
          >
            <FontAwesomeIcon
              icon={faBed}
              className="md:mx-12 mx-5 text-xl md:text-5xl md:w-16 z-1 cursor-pointer hover:text-agreen"
            />
            {showBedCard && (
              <div className="md:left-[-50%] left-[-300%] cursor-pointer absolute md:w-[300px] md:h-[280px] w-[400%] md:top-[-500%] top-[-300%] p-7 bg-awhite border-2 border-agreen shadow-lg rounded-lg z-10">
                <p className="font-text text-black text-sm md:text-base">
                  {t("home.cards.room")}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-center py-20 ">
          <div
            className="relative"
            onMouseEnter={handlePawIconHover}
            onMouseLeave={handlePawIconLeave}
          >
            <FontAwesomeIcon
              icon={faPaw}
              className="md:mx-12 mx-5 text-xl md:text-5xl cursor-pointer md:w-16 z-1 hover:text-agreen"
            />
            {showPawCard && (
              <div className="md:left-[-50%] left-[-50%] cursor-pointer absolute md:w-[300px] md:h-[280px] w-[400%] md:top-[-500%] top-[-750%] p-7 bg-awhite border-2 border-agreen shadow-lg rounded-lg z-10">
                <p className="font-text text-black text-sm md:text-base">
                  {t("home.cards.pets")}
                </p>
              </div>
            )}
          </div>
          <div
            className="relative"
            onMouseEnter={handleCakeIconHover}
            onMouseLeave={handleCakeIconLeave}
          >
            <FontAwesomeIcon
              icon={faCakeCandles}
              className="md:mx-12 mx-5 text-xl md:text-5xl md:w-16 cursor-pointer z-1 hover:text-agreen"
            />
            {showCakeCard && (
              <div className="md:left-[-50%] left-[-150%] cursor-pointer absolute md:w-[300px] md:h-[280px] w-[400%] md:top-[-500%] top-[-750%] p-7 bg-awhite border-2 border-agreen shadow-lg rounded-lg z-10">
                <p className="font-text text-black text-sm md:text-base">
                  {t("home.cards.events")}
                </p>
              </div>
            )}
          </div>
          <div
            className="relative"
            onMouseEnter={handleParkingIconHover}
            onMouseLeave={handleParkingIconLeave}
          >
            <FontAwesomeIcon
              icon={faSquareParking}
              className="md:mx-12 mx-5 text-xl md:text-5xl cursor-pointer md:w-16 z-1 hover:text-agreen"
            />
            {showParkingCard && (
              <div className="md:left-[-50%] left-[-250%] cursor-pointer absolute md:w-[300px] md:h-[280px] w-[400%] md:top-[-500%] top-[-750%] p-7 bg-awhite border-2 border-agreen shadow-lg rounded-lg z-10">
                <p className="font-text text-black text-sm md:text-base">
                  {t("home.cards.parking")}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <p className="md:text-lg  flex text-justify font-title pt-12 pb-10 mx-8 md:mx-24">
        {t("home.poesie-1")}
      </p>
      <p className="md:text-lg  flex text-justify font-title pb-2 mx-8 md:mx-24">
        {t("home.poesie-2")}
      </p>
      <p className="md:text-lg  flex  text-justify font-title pb-2 mx-8 md:mx-24">
        {t("home.poesie-3")}
      </p>
      <p className="md:text-lg  flex  text-justify font-title pb-2 mx-8 md:mx-24">
        {t("home.poesie-4")}
      </p>
      <p className="md:text-lg  flex  text-justify font-title pb-2 mx-8 md:mx-24">
        {t("home.poesie-5")}
      </p>
      <p className="md:text-lg  flex  text-justify font-title pb-2 mx-8 md:mx-24">
        {t("home.poesie-6")}
      </p>
      <p className="md:text-lg  flex  text-justify font-title pb-2 mx-8 md:mx-24">
        {t("home.poesie-7")}
      </p>
      <p className="md:text-lg  flex  text-justify font-title pb-20 mx-8 md:mx-24">
        {t("home.poesie-8")}
      </p>

      <hr className="border-2 border-agreen" />

      <div className="bg-awhite">
        <p className="text-4xl flex justify-center text-center font-title text-agreen pt-20 mx-8 md:mx-0">
          {t("home.rent")}
        </p>
        <p className="flex justify-center italic md:text-center text-justify md:text-2xl text-lg font-title mx-8 md:mt-10 mt-5 md:mx-10">
          {t("home.rent-sub")}
        </p>

        <div className="md:flex md:justify-around mx-12">
          <p className="font-title  md:w-1/3 pt-12 md:mx-12 text-justify">
            {t("home.rent-desc-1.1")}
            <br />
            {t("home.rent-desc-1.2")}
            <br />
            {t("home.rent-desc-1.3")}
            <br />
            {t("home.rent-desc-1.4")}
          </p>

          <p className="font-text  md:w-1/3 pt-12 md:mx-12 text-justify">
            {t("home.rent-desc-2.1")}
            <br />
            {t("home.rent-desc-2.2")}
            <br />
            {t("home.rent-desc-2.3")}
            <br />
            {t("home.rent-desc-2.4")}
          </p>
        </div>

        {/*      <div className="pt-20 md:px-20 px-12 flex pb-20">
          <img src={Mae} alt="casa mae" className="w-[50%] object-cover" />
          <div className="flex flex-col">
            <img src={Picnic} alt="table picnic" className="ml-10 w-[70%]" />
            <img src={Mae2} alt="casa mae 2" className="ml-10 pt-10 w-[70%]" />
          </div>
        </div> */}

        <div className="md:mx-20  h-[700px]  relative group">
          <img
            className="absolute md:px-[7rem] mx-auto pt-16 inset-0  h-full object-cover  opacity-100 group-hover:opacity-0 duration-500 scale-75 md:scale-100"
            src={casaMae}
            alt="casamae"
          />
          <img
            className="absolute md:px-[7rem] mx-auto pt-16 inset-0  h-full object-cover opacity-0 group-hover:opacity-100 duration-500 scale-75 md:scale-100"
            src={Aquarelle1}
            alt="aquarelle"
          />
        </div>

        <div>
          <p className="flex justify-center md:text-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 md:mx-10 mx-8 ">
            {t("home.interested")}
          </p>
          <div className="flex justify-center py-12 pb-20 rounded-full">
            <Link to="/contact">
              <button className="bg-ayellow hover:text-awhite font-title font-bold py-2 px-4 rounded-full md:mx-10 mx-4 hover:bg-agreen duration-500">
                {t("contact.title")}
              </button>
            </Link>
            <Link to="/photos">
              <button className="bg-ayellow hover:text-awhite font-title font-bold py-2 px-4 rounded-full md:mx-10 mx-4 hover:bg-agreen duration-500">
                {t("home.photos")}
              </button>
            </Link>
          </div>
        </div>

        <hr className="border-2 border-agreen" />

        <p className="text-4xl flex justify-center text-center font-title text-agreen pt-20 mx-8 md:mx-0">
          {t("home.grenier")}
        </p>
        <p className="flex justify-center italic md:text-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 md:mx-24 mx-12">
          {t("home.room-sub-3")}
        </p>

        <p className="flex justify-center md:text-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 md:mx-24 mx-12">
          {t("home.room-sub")}
        </p>



        <div className="md:mx-20  h-[700px]  relative group">
          <img
            className="absolute md:px-[7rem] mx-auto pt-16 inset-0  h-full object-cover  opacity-100 group-hover:opacity-0 duration-500 scale-75 md:scale-100"
            src={casaMain}
            alt="casamain"
          />
          <img
            className="absolute md:px-[7rem] mx-auto pt-16 inset-0  h-full object-cover opacity-0 group-hover:opacity-100 duration-500 scale-75 md:scale-100"
            src={Aquarelle2}
            alt="aquarelle"
          />
        </div>

        <div className="md:flex md:justify-around mx-12">
          <p className="font-text  md:w-1/3 pt-12 md:mx-12 text-justify">
            {t("home.grenier-desc-1")}
            <br />
            {t("home.grenier-desc-2")}
            <br />
            {t("home.grenier-desc-3")}
          </p>

          <p className="font-text  md:w-1/3 pt-12 md:mx-12 text-justify">
            {t("home.grenier-desc-4")}
            <br />
            {t("home.grenier-desc-5")}
          </p>
        </div>

        <p className="text-4xl flex justify-center text-center font-title text-agreen pt-20 mx-8 md:mx-0">
          {t("home.grange")}
        </p>

        <p className="flex justify-center italic md:text-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 md:mx-24 mx-12">
          {t("home.grange-sub")}
        </p>

        <div className="md:mx-20  h-[700px]  relative group">
          <img
            className="absolute md:px-[7rem] mx-auto pt-16 inset-0  h-full object-cover  opacity-100 group-hover:opacity-0 duration-500 scale-75 md:scale-100"
            src={celeiro}
            alt="casamain"
          />
          <img
            className="absolute md:px-[7rem] mx-auto pt-16 inset-0  h-full object-cover opacity-0 group-hover:opacity-100 duration-500 scale-75 md:scale-100"
            src={Aquarelle3}
            alt="aquarelle"
          />
        </div>

        <div className="md:flex md:justify-around mx-12">
          <p className="font-text  md:w-1/3 pt-12 md:mx-12 text-justify">
            {t("home.grange-desc-1")}
            <br />
            {t("home.grange-desc-2")}
          </p>

          <p className="font-text  md:w-1/3 pt-12 md:mx-12 text-justify">
            {t("home.grange-desc-3")}
            <br />
            {t("home.grange-desc-4")}
          </p>
        </div>

        <div>
        <p className="flex justify-center italic md:text-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 md:mx-24 mx-12">
          {t("home.room-sub-2")}
        </p>
          <p className="flex justify-center md:text-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 md:mx-10 mx-8 ">
            {t("home.interested")}
          </p>
          <div className="flex justify-center py-12 pb-20 rounded-full">
            <Link to="/contact">
              <button className="bg-ayellow hover:text-awhite font-title font-bold py-2 px-4 rounded-full md:mx-10 mx-4 hover:bg-agreen duration-500">
                {t("contact.title")}
              </button>
            </Link>
            <Link to="/photos">
              <button className="bg-ayellow hover:text-awhite font-title font-bold py-2 px-4 rounded-full md:mx-10 mx-4 hover:bg-agreen duration-500">
                {t("home.photos")}
              </button>
            </Link>
          </div>
        </div>

        <hr className="border-2 border-agreen" />

        <p className="text-4xl flex justify-center text-center font-title text-agreen pt-20">
          {t("home.casa")}
        </p>

        <p className="flex justify-center italic md:text-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 md:mx-24 mx-12">
          {t("home.casa-sub")}
        </p>
        <div className="md:mx-20  h-[700px]  relative group">
          <img
            className="absolute md:px-[7rem] mx-auto pt-16 inset-0  h-full object-cover  opacity-100 group-hover:opacity-0 duration-500 scale-75 md:scale-100"
            src={casanova}
            alt="casamain"
          />
          <img
            className="absolute md:px-[7rem] mx-auto  pt-16 inset-0  h-full object-cover opacity-0 group-hover:opacity-100 duration-500 scale-75 md:scale-100"
            src={Aquarelle5}
            alt="aquarelle"
          />
        </div>


        <div className="md:flex md:justify-around mx-12">
          <p className="font-text  md:w-1/3 pt-12 md:mx-12 text-justify">
          {t("home.casa-sub-2")}
          </p>
          <p className="font-text  md:w-1/3 pt-12 md:mx-12 text-justify">
          {t("home.casa-sub-3")}
          </p>
        </div>



        <div>
          <p className="flex justify-center md:text-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 md:mx-10 mx-8">
            {t("home.interested")}
          </p>
          <div className="flex justify-center py-12 pb-20 rounded-full">
            <Link to="/contact">
              <button className="bg-ayellow hover:text-awhite font-title font-bold py-2 px-4 rounded-full md:mx-10 mx-4 hover:bg-agreen duration-500">
                {t("contact.title")}
              </button>
            </Link>
            <Link to="/photos">
              <button className="bg-ayellow hover:text-awhite font-title font-bold py-2 px-4 rounded-full md:mx-10 mx-4 hover:bg-agreen duration-500">
                {t("home.photos")}
              </button>
            </Link>
          </div>
        </div>

        <hr className="border-2 border-agreen" />

        <p className="text-4xl flex justify-center text-center font-title text-agreen pt-20 mx-8 md:mx-0">
          {t("home.piscine")}
        </p>

        <div className='md:mx-20 h-[700px]'>
          <img
            src={Aquarelle0}
            alt="piscine"
            className="md:px-[7rem] mx-auto pt-16 h-full object-cover scale-75 md:scale-100"
          />
        </div>

        <p className="flex justify-center md:text-center text-justify italic md:text-2xl text-lg font-title md:mt-20 mt-5 md:mx-24 mx-12">
          {t("home.piscine-piscine")}
        </p>

        <p className="flex justify-center md:text-center text-justify md:text-xl text-lg font-title md:mt-10 mt-5 md:mx-24 mx-12">
          {t("home.piscine-sub")}
        </p>

        <p className="flex justify-center md:text-center text-justify italic md:text-2xl text-lg font-title md:mt-20 mt-5 md:mx-24 mx-12">
          {t("home.mini-golf")}
        </p>

        <p className="flex justify-center md:text-center text-justify md:text-xl text-lg font-title md:mt-10 mt-5 md:mx-24 mx-12">
          {t("home.mini-golf-sub")}
        </p>

        <p className="flex justify-center md:text-center text-justify italic md:text-2xl text-lg font-title md:mt-20 mt-5 md:mx-24 mx-12">
          {t("home.sentiers")}
        </p>

        <p className="flex justify-center md:text-center text-justify md:text-xl text-lg font-title md:mt-10 mt-5 md:mx-24 mx-12">
          {t("home.sentiers-sub")}
        </p>
        <p className="flex justify-center md:text-center text-justify md:text-xl text-lg font-title mt-5 md:mx-24 mx-12">
          {t("home.sentiers-sub-2")}
        </p>
        <p className="flex justify-center md:text-center text-justify md:text-xl text-lg font-title  mt-5 md:mx-24 mx-12">
          {t("home.sentiers-sub-3")}
        </p>
        <p className="flex justify-center md:text-center text-justify md:text-xl text-lg font-title  mt-5 md:mx-24 mx-12">
          {t("home.sentiers-sub-4")}
        </p>
        <p className="flex justify-center md:text-center text-justify md:text-xl text-lg font-title mt-5 md:mx-24 mx-12 pb-28">
          {t("home.sentiers-sub-5")}
        </p>
      </div>
      </div>
    </main>
  );
};

export default Home;
